














































import { mapState } from 'vuex';
import moment from 'moment';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import RepositoryFactory from '@/services/RepositoryFactory';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import PrinterMixin from '@/mixins/PrintMixin';

const factory = new RepositoryFactory();

export default PrinterMixin.extend({
  name: 'BoxDetails',
  data() {
    return {
      label: '',
      data: {},
      currentBoxShippingData: {},
      lastBoxShippingData: {},
      boxShippingQuery: '',
      decodedPDF: '/sample.pdf',
      loading: false,
    };
  },
  components: {
    TheFabButton,
    DefaultLayout: () => import('@/layouts/Default.vue'),
    BoxShippingData: () => import('@/components/boxes/BoxShippingData.vue'),
  },
  computed: {
    ...mapState('reboxes', ['encodedLabelPdf']),
    ...mapState('globals', ['token']),
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    async getSingleBox() {
      const data = await this.boxesRepository.getOne(this.$route.params.code);
      const {
        code,
        status,
        createdAt,
        updatedAt,
        createdBy,
        updatedBy,
        currentBoxShipping,
        lastBoxShipping,
      } = data.data;

      const filteredData = {
        [this.$t('box-details.reboxNo') as string]: code,
        status: this.$t(`boxOverviewFilters.${status}`),
        // eslint-disable-next-line quote-props
        [this.$t('box-details.created-at') as any]: this.formatDate(createdAt),
        // eslint-disable-next-line quote-props
        [this.$t('box-details.updated-at') as any]: this.formatDate(updatedAt),
        [this.$t('box-details.created-by') as string]: createdBy,
        [this.$t('box-details.updated-by') as string]: updatedBy,
      };

      this.data = filteredData;
      this.$headerService.setHeaderTitle(`${this.$t('box-details.reboxNo')}: ${code}`);

      if (currentBoxShipping) {
        const {
          boxShipmentLabel: { file },
          order,
          trackingNr,
          trackingLink,
        } = currentBoxShipping;
        this.label = file;
        const {
          custNr,
          custName,
          custSurname,
          custEmail,
          delAddressStreet,
          delAddressStreetNr,
          delAddressCity,
          delAddressZip,
          orderDate,
          orderNr,
        } = order;
        const orderData = {
          [this.$t('box-details.customer-email') as string]: custEmail,
          [this.$t('box-details.customer') as string]: `${custName} ${custSurname}`,
          [this.$t('box-details.customer-number') as string]: custNr,
          [this.$t(
            'box-details.customer-address',
          ) as string]: `${delAddressStreet} ${delAddressStreetNr}, ${delAddressZip} ${delAddressCity}`,
          [this.$t('box-details.orderDate') as string]: this.formatDate(orderDate),
          [this.$t('box-details.orderNr') as string]: orderNr,
          [this.$t('box-details.tracking-number') as string]: trackingNr,
          ['trackingLink' as string]: trackingLink,
        };
        this.currentBoxShippingData = orderData;
      }
      if (lastBoxShipping) {
        const {
          boxShipmentLabel: { file },
          order,
          trackingNr,
          trackingLink,
        } = lastBoxShipping;
        this.label = file;
        const {
          custNr,
          custName,
          custSurname,
          custEmail,
          delAddressStreet,
          delAddressStreetNr,
          delAddressCity,
          delAddressZip,
          orderDate,
          orderNr,
        } = order;
        const orderData = {
          [this.$t('box-details.customer-email') as string]: custEmail,
          [this.$t('box-details.customer') as string]: `${custName} ${custSurname}`,
          [this.$t('box-details.customer-number') as string]: custNr,
          [this.$t(
            'box-details.customer-address',
          ) as string]: `${delAddressStreet} ${delAddressStreetNr}, ${delAddressZip} ${delAddressCity}`,
          [this.$t('box-details.orderDate') as string]: this.formatDate(orderDate),
          [this.$t('box-details.orderNr') as string]: orderNr,
          [this.$t('box-details.tracking-number') as string]: trackingNr,
          ['trackingLink' as string]: trackingLink,
        };
        this.lastBoxShippingData = orderData;
      }
    },
    toggleLoading(): void {
      this.loading = !this.loading;
    },
    formatDate(value: any) {
      return moment(value).format('DD-MM-YYYY');
    },
    isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    },
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'BoxOverview' },
    });
    this.getSingleBox();
  },
});
